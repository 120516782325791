import React from 'react';
import Wrap from '../Wrap';
import { Link } from 'gatsby';
import { productsHolder } from './products.module.scss';

export const Products = ({ products }) => {
  return (
    <ul className={productsHolder}>
      {products.map((product) => (
        <li key={product.slug} className={product.linkProduct.linkProduct ? 'link' : ''}>
          <Wrap
            condition={product.linkProduct.linkProduct}
            wrapper={(children) => (
              <Link to={`/product/${product.slug}`} title={product.title}>
                {children}
              </Link>
            )}>
            <>{product.title}</>
          </Wrap>
        </li>
      ))}
    </ul>
  );
};

export default Products;
