import React, { useRef, useEffect, useState, useMemo } from 'react';
import Seo from '../components/Seo';
import { searchHolder } from './search.module.scss';
import useProducts from '../hooks/useProducts';

import Products from '../components/categoryPages/Products';

export default function SearchPage() {
  const allProducts = useProducts();
  const [search, setSearch] = useState('');

  // get the search query from the url
  useEffect(() => {
    if (typeof window === 'undefined') return;
    let urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('search');
    if (searchQuery) {
      setSearch(searchQuery);
    }
  }, []);

  const changeSearch = (e) => {
    setSearch(e.target.value);
    // update the url
    if (typeof window === 'undefined') window.history.pushState({}, '', `?search=${e.target.value}`);
  };

  //create a ref for the search input
  const searchInput = useRef(null);

  // console.log(allProducts);

  const products = useMemo(() => {
    const titleSearchResults = allProducts?.filter((product) => {
      return product.title.toLowerCase().includes(search?.toLowerCase());
    });
    const tagSearchResults = allProducts?.filter((product) => {
      return product.tags.nodes?.some((tag) => tag.name.toLowerCase().includes(search?.toLowerCase()));
    });
    const categoriesSearchResults = allProducts?.filter((product) => {
      return product.categories.nodes?.some((category) => category.name.toLowerCase().includes(search?.toLowerCase()));
    });
    let allSearchResults = [...titleSearchResults, ...tagSearchResults, ...categoriesSearchResults];

    // remove products with the same slug
    allSearchResults = allSearchResults.filter((product, index, self) => {
      return index === self.findIndex((p) => p.slug === product.slug);
    });

    // sort the products by name
    allSearchResults = allSearchResults.sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    return allSearchResults;
  }, [search, allProducts]);

  //focus the search input on page load
  useEffect(() => {
    searchInput.current.focus();
  }, []);

  return (
    <article className={`${searchHolder} articleAlignTop`}>
      <header>
        <h1>Search MCX Films:</h1>
        <input type="text" value={search} onChange={changeSearch} placeholder="e.g. Zeiss / Lens / 4k" ref={searchInput} />
      </header>
      <Products products={products} />
    </article>
  );
}

export const Head = () => <Seo title="Search MCX Films" />;
